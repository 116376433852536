import { Note } from '@/model/Model';

const notesStub: Array<Note> = [
  {
    id: '1',
    content: 'Hello world',
    tags: ['todo', 'idea'],
    attributes: {
      'by': 'me',
    },
    createdAt: 1609596858563,
  },
  {
    id: '2',
    content: 'Second note',
    tags: ['todo', 'work'],
    attributes: {},
    createdAt: 1609595955756,
  },
  {
    id: '3',
    content: 'The third note',
    tags: ['diy', 'work'],
    attributes: null,
    createdAt: 1609594823539,
  },
];
export default notesStub;
