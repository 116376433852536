
import { defineComponent } from 'vue';
import { getRandomColor } from '@/utils/lst_utils';
 
export default defineComponent({
  name: 'Tag',
  props: {
    name: String,
  },
  setup(props) {
    return {
      color: getRandomColor(props.name || ''),
    }
  },
});
