<template>
  <div class="attribute-wrapper">
    <div class="attribute-name">{{name}}:&nbsp;</div>
    <div class="attribute-value">{{value}}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Attribute',
  props: {
    name: String,
    value: String,
  },
});
</script>

<style scoped>
.attribute-wrapper {
  display: flex;
  flex-direction: row;
  padding: 4px;
  border-radius: 4px;
  background-color: #EFEFEF;
}

.attribute-name {
  font-weight: bold;
}

</style>