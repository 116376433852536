
import { defineComponent } from 'vue';
import marked from 'marked';
import moment from 'moment';

import Attribute from '@/components/Attribute.vue';
import Tag from '@/components/Tag.vue';

export default defineComponent({
  name: 'Card',
  components: {
    Attribute,
    Tag
  },
  props: {
    note: Object,
  },
  setup(props) {    
    const compiledContent = marked(props.note?.content);
    const time = moment.unix(props.note?.createdAt).format("hh:mm");
    const attributes = Object.entries(props.note?.attributes || {});
    return {
      compiledContent,
      attributes,
      time,
    }
  },
});
