
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Attribute',
  props: {
    name: String,
    value: String,
  },
});
