<template>
  <div class="wrapper shadow">
    <div class="title">
      <a href="/">
      Life Stream Feed
      </a>
    </div>
    <div class="spacer"></div>
    <div class="user-container">
      <GrAvatar 
        v-if="profile.loaded"
        :email="profile.email"/>
      <div class="username">{{username}}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GrAvatar from '@/components/GrAvatar.vue';

export default defineComponent({
  name: 'Header',
  components: {
    GrAvatar,
  },
  props: {
    username: String,
    profile: Object,
  },
  setup(props) {
    console.log('Header', props.profile);
  },
});
</script>

<style scoped>

.shadow {
  -webkit-box-shadow: 0px 0px 3px 0px #2d2d2d;
  box-shadow: 0px 0px 3px 0px #2d2d2d;
}

.wrapper {
  height: 64px;
  display: flex;
  flex-direction: row;
  background-color: #99E5E5;
}

.title {
  padding-left: 32px;
  line-height: 64px;
  font-size: 1.5em;
  font-weight: bold;
}

.title a {
  text-decoration: none;
  color: #1F1F1F;
}

.spacer {
  flex-grow: 1;
}

.user-container {
  display: flex;
  flex-direction: row;
  line-height: 64px;
  padding-right: 32px;
  align-items: center;
}

.username {
  padding-left: 12px;
}

</style>
