import { User } from '@/model/Model';

const usersStub: Array<User> = [
  {
    email: 'test-alice@example.com',
    username: 'alice',
  },
  {
    email: 'test-bob@example.com',
    username: 'bob',
  },
  {
    email: 'test-carol@example.com',
    username: 'carol',
  },
]

export default usersStub;