
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import moment from 'moment';

import Header from '@/components/Header.vue';
import Card from '@/components/Card.vue';
import notesStub from './stubs/notes';

import { Note } from '@/model/Model';

const BASE_URL = process.env.VUE_APP_BASE_URL;

const dateGetter = (item: Note) => {
  const millisInDay = 1000 * 3600 * 24;
  return Math.floor(item.createdAt / millisInDay) * millisInDay;
}

const groupByDate = (items: Array<any>, dateGetter: Function) => {
  const grouped: Record<any, any> = {};
  items.forEach(item => {
    const key = dateGetter(item);
    const group: Array<any> = grouped[key] || [];
    group.push(item);
    grouped[key] = group;
  })
  const groupedList = Object.entries(grouped);
  groupedList.sort((a: Array<any>, b: Array<any>) => b[0] - a[0]);
  
  groupedList.forEach((item) => {
    const date = new Date(Number.parseInt(item[0]));    
    item[0] = moment(date).format('YYYY-MM-DD');
  });

  return groupedList;
}

export default defineComponent({
  name: 'User',
  components: {
    Header,
    Card,
  },
  setup() {
    const route = useRoute();
    const notes = ref(notesStub);
    const groupedNotes = ref(groupByDate(notesStub, dateGetter));
    const profile = ref({loaded: false,});
    const username = route.params.username;


    const loadPublicStream = async () => {
      const { data } = await axios.get(`${BASE_URL}/api/public/${username}/stream`);
      notes.value = data.map((note: any) => {
        return {
          id: note.id,
          content: note.content,
          tags: note.tags,
          attributes: note.attributes,
          createdAt: note.createdAt,
        }
      });
      groupedNotes.value = groupByDate(notes.value, dateGetter);
    }

    const loadPublicProfile = async () => {
      const { data } = await axios.get(`${BASE_URL}/api/public/${username}/profile`);           
      profile.value = Object.assign({loaded: true}, data);
      console.log('Profile loaded');
    }

    loadPublicStream();
    loadPublicProfile();

    return {
      username,
      notes,
      profile,
      groupedNotes,
    }
  },
});
