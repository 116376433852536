
import { defineComponent } from 'vue';
import GrAvatar from '@/components/GrAvatar.vue';

export default defineComponent({
  name: 'Header',
  components: {
    GrAvatar,
  },
  props: {
    username: String,
    profile: Object,
  },
  setup(props) {
    console.log('Header', props.profile);
  },
});
