<template>
  <div class="wrapper">
    <img
      v-if="profile.found" 
      :src="profile.imageUrl"
      class="avatar"
      alt="Avatar"/>
    <div 
      v-if="!profile.found"
      :style="{background: altColor}"
      class="alt">
      {{altText}}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import MD5 from "crypto-js/md5";
import axios from 'axios';
import { getRandomColor } from '@/utils/lst_utils';

export default defineComponent({
  name: 'GrAvatar',
  props: {
    email: String,
  },
  setup(props) {
    console.log(props.email);
    
    const grAvatarProfile = ref({loaded: false, found: false, imageUrl: undefined,});
    const emailMD5 = MD5(props.email || '').toString();
    const loadGrAvatarProfile = async () => {
      try {
        const { data } = await axios.get(`https://en.gravatar.com/${emailMD5}.json`);
        grAvatarProfile.value = {
          loaded: true,
          found: true,
          imageUrl: data.entry[0].thumbnailUrl,
        };        
      } catch (error) {
        console.log(error);
        grAvatarProfile.value = {
          loaded: true, 
          found: false,
          imageUrl: undefined,
        };
      }            
    };

    loadGrAvatarProfile();
    const altText = (props.email || 'u')[0].toUpperCase();
    return {
      profile: grAvatarProfile,
      altColor: getRandomColor(altText),
      altText: altText,
    }
  },
});
</script>

<style scoped>
.wrapper {
  align-items: center;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.alt {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  line-height: 48px;
  font-weight: bold;
  font-size: 1.5em;
}


</style>