<template>
  <div :style="{background: color}">{{name}}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getRandomColor } from '@/utils/lst_utils';
 
export default defineComponent({
  name: 'Tag',
  props: {
    name: String,
  },
  setup(props) {
    return {
      color: getRandomColor(props.name || ''),
    }
  },
});
</script>

<style scoped>
div {
  border-radius: 8px;
  padding: 0.2em 1.2em;
  font-weight: bold;
  color: white;
  background-color: cadetblue;
}
</style>
