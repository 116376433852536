
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import UserCard from '@/components/UserCard.vue';
import usersStub from '@/views/stubs/users';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export default defineComponent({
  name: 'Home',
  components: {
    UserCard,
  },
  setup() {
    const users = ref(usersStub);

    const loadUsers = async () => {
      const { data } = await axios.get(`${BASE_URL}/api/public/users`);
      users.value = data;
    };

    loadUsers();

    return {
      users,
    }
  },
});
