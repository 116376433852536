<template>  
  <a :href="url" class="user-card-wrapper">
    {{username}}
  </a>  
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserCard',
  props: {
    username: String,
  },
  setup(props) {
    return {
      url: `/user/${props.username}`,
    }
  },
});
</script>

<style scoped>
.user-card-wrapper {
  width: 120px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 3px 0px #2d2d2d;
  box-shadow: 1px 1px 3px 0px #2d2d2d;
  padding: 16px;
  background-color: white;
  display: block;
  text-decoration: none;
  color: black;
}

</style>