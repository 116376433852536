<template>
  <div class="wrapper">
    <div v-html="compiledContent" />
    <div v-if="attributes.length" class="attributes-container flex-row">
      <Attribute 
        v-for="attr in attributes" 
        :key="attr[0]"
        :name="attr[0]"
        :value="attr[1]" />
    </div>
    <div class="tags flex-row">
      <Tag v-for="(tag, i) in note.tags" :key="i" :name="tag" />
    </div>
    <div class="time">
      {{time}}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import marked from 'marked';
import moment from 'moment';

import Attribute from '@/components/Attribute.vue';
import Tag from '@/components/Tag.vue';

export default defineComponent({
  name: 'Card',
  components: {
    Attribute,
    Tag
  },
  props: {
    note: Object,
  },
  setup(props) {    
    const compiledContent = marked(props.note?.content);
    const time = moment.unix(props.note?.createdAt).format("hh:mm");
    const attributes = Object.entries(props.note?.attributes || {});
    return {
      compiledContent,
      attributes,
      time,
    }
  },
});
</script>

<style scoped>

.wrapper {
  width: 100%;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 3px 0px #2d2d2d;
  box-shadow: 1px 1px 3px 0px #2d2d2d;
  padding: 16px;  
  text-align: left;
  word-break: break-all;
  background-color: white;
  position: relative;
}

.tags {
  padding-top: 16px;
  gap: 16px;
}

.time {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 0.8em;
  color: #5f5f5f;
}

.attributes-container {
  gap: 4px;
}
</style>
