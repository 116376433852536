<template>
  <div class="wrapper">
    <h1>Users</h1>
    <div class="users">
      <UserCard 
        v-for="user in users"
        :key="user.email"
        :username="user.username"
      />
    </div>
    <div class="spacer" />
    <div class="footer">
      <h2>Want to join?</h2>
      <p>
        Install a CLI-tool
      </p>
      <div class="command">
        pip install life-stream-cli
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import UserCard from '@/components/UserCard.vue';
import usersStub from '@/views/stubs/users';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export default defineComponent({
  name: 'Home',
  components: {
    UserCard,
  },
  setup() {
    const users = ref(usersStub);

    const loadUsers = async () => {
      const { data } = await axios.get(`${BASE_URL}/api/public/users`);
      users.value = data;
    };

    loadUsers();

    return {
      users,
    }
  },
});
</script>

<style scoped>
.wrapper {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;  
}

.users {
  display: flex;
  flex-direction: row;
  gap: 16px;  
}

.spacer {
  flex-grow: 1;
}

.command {
  background-color: #4e4e4e;
  padding: 0.5em 2em 0.5em;
  border-radius: 16px;
  color: white;
  font-weight: bold;  
}

.footer {
  padding-bottom: 90px;
}

</style>
