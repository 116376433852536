export const hash = (str: string) => {
  let hash = 0, i, chr;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const getRandomColor = (str: string) => {
  const colors = ['#6b5b95', '#feb236', '#d64161', '#ff7b25', '#878f99', '#82b74b', '#034f84', '#50394c'];
  return colors[hash(str) % colors.length];
}